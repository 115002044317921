<template>
  <el-form ref="form" :model="form" :rules="rules" size="small" label-position="top">
    <el-form-item label="名称" prop="name">
      <el-input v-model="form.name"></el-input>
      <span class="help">角色名称，必填项，允许小写字母/数字/短横线组成</span>
    </el-form-item>

    <el-form-item label="描述" prop="description">
      <el-input v-model="form.description"></el-input>
    </el-form-item>

    <el-form-item label="权限" prop="permissons">
      <el-transfer
        v-model="form.permissons"
        :props="{
          key: 'name',
          label: 'name'
        }"
        filterable
        :filter-method="filterMethod"
        :data="permissionList"
        :titles="['全部权限', '已选择']"
        class="role-transfer"
        @change="permissonsChange"
      >
      </el-transfer>
    </el-form-item>

    <slot name="button"></slot>
  </el-form>
</template>

<script>
import { permissionList } from "api/role";

export default {
  props: {
    form: Object
  },

  data() {
    return {
      rules: {
        name: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],

        description: { required: true, message: this.$t("rules.require"), trigger: "blur" }
      },

      permissionList: [],

      filterMethod(query, item) {
        return item.name.indexOf(query) > -1;
      }
    };
  },

  methods: {
    getPermissionList() {
      permissionList().then(response => {
        if (response.code === 0) {
          this.permissionList = response.data.items;
        }
      });
    }
  },

  mounted() {
    this.getPermissionList();
  }
};
</script>

<style lang="scss" scoped>
.role-transfer {
  /deep/.el-transfer-panel {
    width: 40%;

    .el-transfer-panel__body {
      height: 100%;
    }
    .el-transfer-panel__list.is-filterable,
    .el-transfer-panel__empty {
      height: 400px;
    }
  }
}
</style>
